module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"riff-gatsby-starter-sanity","short_name":"Sanity Starter","start_url":"/","icon":"src/assets/images/favicon.jpg","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b5656761d97501fd80733c8055c39f0b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"mobile":0,"tablet":600,"desktop":1280,"desktop_large":1920}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://p.typekit.net"],"web":[{"name":["antique-olive-nord","antique-olive"],"file":"https://use.typekit.net/ivn6zpw.css"},{"name":"heebo","file":"https://use.typekit.net/ivn6zpw.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
